<template>
<div style="background:#f7f7f7;padding-bottom:62px;">
    <iframe  src="/vendor/apply.html"></iframe>
    <bottomTabNav :index="1"></bottomTabNav>

</div>
</template>
<script type="text/javascript">
import bottomTabNav from "@/components/bottomTabNav";
import request from "@/utils/request";
import guideLogin from "@/utils/guideLogin";
import { Toast } from 'vant';
export default {
    name: "MinePage",
    components: { bottomTabNav },
    data() {
        return {
            containerHeight:0
        };
    },
    created() {
		this.containerHeight = document.documentElement.clientHeight - 62 + "px";
		this.hideShare();
    },
    methods: {
    }
}
</script>
<style type="text/css" scoped="">
    iframe{
        width:100%;
        border: none;
        height: 520px;;
    }
</style>
